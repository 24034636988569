<template>
  <div class="productDetailsPageBox page-info-content">
    <Details-page :list-data="listData" :item-data="itemData" :table-title="'代采商品列表'" :detail-obj="detailObj" :details-item-arr="detailsItemArr" />
    <div class="cancelButton">
      <el-button size="small" @click="$router.go(-1)">
        取消
      </el-button>
    </div>
  </div>
</template>

<script>
import DetailsPage from '@/components/DetailsPage.vue'
export default {
  components: { DetailsPage },
  data() {
    return {
      detailObj: { name: '钢厂', state: '00' },
      itemData: [
        { label: '品名', prop: 'name' },
        { label: '件重(吨)', prop: 'name' },
        { label: '材质', prop: 'name' },
        { label: '规格', prop: 'name' }
      ],
      listData: [{ name: '小刚' }],
      detailsItemArr: [
        { label: '合作钢厂', value: 'name' },
        { label: '产品名称', value: 'name' },
        { label: '单笔采购上限', value: 'name' },
        { label: '保证金比例', value: 'name', rightWords: '%' },
        { label: '代采费率', value: 'name', rightWords: '% / 天' },
        { label: '代采期限', value: 'name', rightWords: '天' },
        { label: '货款延期支付天数', value: 'name', rightWords: '天' },
        { label: '逾期费率', value: 'name', rightWords: '% / 天' },
        { label: '结算日期', value: 'name', rightWords: '号', leftWords: '每月' },
        { label: '开票方式', value: 'name' },
        { label: '协议模板', value: 'name', link: 'hdofisd' },
        { label: '状态', value: 'state', child: [{ id: '01', val: '禁用' }, { id: '00', val: '启用' }] },
        { label: '备注', value: 'name' }
      ],
      breadcrumbItem: [{ path: '/', name: '首页' }, { path: '/productManagement/index', name: '产品管理' }, { path: '', name: '产品详情' }]
    }
  }
}
</script>
<style lang="scss">
.productDetailsPageBox{
  .cancelButton{
    text-align: center;
  }
}
</style>
